<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
@font-face {
  font-family: 'Pretendard, sans-serif';
  src: url('../src/assets/fonts/Pretendard-Regular.ttf');
}

#app{
  margin: 0px;
  padding: 0px;
  width: 100vw;
}

body {
  margin: 0;
  font-family: 'Pretendard, sans-serif';
}

html{
  font-size: 100%;
}
</style>
