<template>
    <div id="history_main">
        <div id="History_Header">
            <Header />
        </div>
        
        <div id="History_Body">
            <div class="history_Container">
                <div class="history_title">
                    <h2>회사연혁</h2>
                    <p>
                    BIMPeers는 계속해서 새로운 길을 만들어 가고 있습니다.<br />
                    겉으로 보이는 화려함을 추구하기 보다는 내실 있는 회사가 되도록 노력하겠습니다.
                    </p>
                </div>

            <!-- <p class="columnBar"></p> -->
                <div class="history_contents" ref="content">
                    <div class="left_Content">
                        <div class="Content_Wrapper" v-for="(item, index) in historyItems" :key="index">
                            <h4>{{ item.year }}</h4>
                            <ul>
                                <li><strong>{{ item.month }}</strong><span v-html="item.description"></span></li>
                                <li><strong>{{ item.month2 }}</strong>{{ item.description2 }}</li>
                            </ul>
                            
                        </div>
                    </div>
                    
                </div>
                
            </div>
            <img class="BottomImg" src="../../assets/images/about/city.png" alt="">
        </div>

        <div id="History_Footer">
            <Footer />
        </div>
        
    </div>
</template>

<style scoped>
@import "../../assets/css/about/history.css";
@import "../../assets/css/reset.css";
</style>

<script>
import Header from "../../assets/components/PageHeader.vue";
import Footer from "../../assets/components/PageFooter.vue";

export default {
    name: "App",
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            historyItems: [
                {
                    year: "2023",
                    month: "01",
                    description: "삼우종합건축사사무소 연간계약(BIM 플러그인 개발) 체결",
                },
                {
                    year: "2019",
                    month: "12",
                    description: "삼성엔지니어링 업무협약(MOU)체결",
                },
                {
                    year: "2017",
                    month: "03",
                    description: "국토교통과학기술진흥원 5개년 국가연구개발사업<br>(개방형 BIM 기반의 건축설계 자동화지원 기술 및 첨단 유지관리 기반 기술 개발) 수행",
                },
                {
                    year: "2016",
                    month: "05",
                    description:"XENOPAN(대한민국 대표 프로젝트 메신저) 개발",
                },
                {
                    year: "2014",
                    month: "12",
                    month2:"01",
                    description: "기업부설연구소 설립",
                    description2: "Revit 데이터 연동 Web Viewer 개발",
                },
                {
                    year: "2010",
                    month: "03",
                    description: "벤처기업 인증",
                },
                {
                    year: "2008",
                    month: "06",
                    month2:"02",
                    description: "Build London Live 2008 (Use Of Interoperability 상) 수상",
                    description2: "BIMPeers 설립",
                },
                // ... 다른 연혁 항목들
            ],
        };
    },

    computed: {
        getHistoryItemPositions() {
            return this.historyItems.map((item, index) => {
                return {
                    index,
                    position: document.querySelector(`#history_main .left_Content .Content_Wrapper:nth-child(${index + 1})`).getBoundingClientRect(),
                };
            });
        },
    },

    mounted() {
        this.contentObserver = new IntersectionObserver(this.handleIntersection, {
            root: null,
            rootMargin: "0px",
            threshold: 0.4,
        });
        this.contentObserver.observe(this.$refs.content);
        window.scrollTo(0, 0);
    },

    methods: {
        isItemInViewport(index) {
            const itemPosition = this.getHistoryItemPositions[index].position;
            return itemPosition.top >= 0 && itemPosition.bottom <= window.innerHeight;
        },

        handleIntersection(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // entry.target은 현재 보이는 Content_Wrapper 요소를 나타냅니다.
                    // 해당 요소의 년도를 가져와 화면에 표시할 수 있습니다.
                    const year = entry.target.querySelector("h4").textContent;
                    console.log("현재 년도: " + year);

                    this.isAnimationStarted = true;
                    this.year--;
                    this.contentObserver.unobserve(this.$refs.content);
                }
            });
        },
    },
};
</script>
