<template>
  <div id="Product_Root">
    <section id="Product_Header">
      <Header/>
    </section>
    <section id="Product_Body">
      <div id="prod_content">
        <div class="Prod_Tit">
          <div class="intro">
            <h4>제품소개</h4>
          <p>
            BIMPeers는 프로젝트를 <strong>효율적으로 통합관리 할 수 있는 솔루션</strong>을 제공합니다.
          </p>
          </div>
          
          <div class="contactBox">
            <h4>
              <img src="../../assets//images/icons/tel.png" alt="">제품 및 커스터마이징 문의</h4>
            <ul>
              <li>전&nbsp;&nbsp;&nbsp;&nbsp;화 | 070-7767-2505</li>
              <li>이메일 | help@bimpeers.com</li>
            </ul>
          </div>
        </div>
        <div class="Product_List">
          <div class="Product_Info">
            <div class="IVWrap" id="list1 product-section-1">
              <div class="I-VIEW_Tit">
                <h2>BIM 모델링 자동화 프로그램</h2>
                <p class="I-VIEW_p">
                  - Revit 기반의 모델링 자동화 Add-in 소프트웨어 개발<br>
                  - 업무프로세스 분석 및 업무 자동화 컨설팅
                </p>
              </div>
  
              <div class="item">
                <div class="Slider_Container">
                  
                  <div class="Slider" :style="{ transform: `translateX(-${currentSlide * 100}%)` }">
                    <!-- <img src="../../assets//images//icons/router2.png" class="prev" @click="prevSlide"> -->
                    <div class="Slider_Item" v-for="(image, index) in images" :key="index">
                      <!-- <img src="../../assets//images//icons/router22.png" class="prevSlide" @click="prevSlide"> -->
                      <img :src="image" alt="Image {{index + 1}}">
                      <!-- <img src="../../assets//images//icons/router2.png" class="nextSlide" @click="nextSlide"> -->
                    </div>
                    <!-- <img src="../../assets//images//icons/router2.png" class="next" @click="nextSlide"> -->
  
                  </div>
                  
                  <div class="Slider_Buttons">
                    <button v-for="(image, index) in images" :key="index" @click="goToSlide(index)" :class="{ active: index === currentSlide}">
                      {{index + 1}}
                    </button>
                  </div>
                  
                </div>
              </div>
            </div>
  
            <div class="WvWrap" id="list2 product-section-2">
              <div class="item">
                <div class="Slider_Container">
                  <div class="Slider" :style="{ transform: `translateX(-${currentWv * 100}%)` }">
                    <!-- <img src="../../assets//images//icons/router22.png" class="prevSlide" @click="prevSlideWv"> -->
                    <div class="Slider_Item" v-for="(image, index) in Wvimages" :key="index">
                      <img :src="image" alt="Image {{index + 1}}">
                    </div>
                    <!-- <img src="../../assets//images//icons/router2.png" class="nextSlide" @click="nextSlideWv"> -->
                  </div>
            
                  <div class="Slider_Buttons">
                    <button v-for="(image, index) in Wvimages" :key="index" @click="goToSlideWv(index)" :class="{ active: index === currentWv}">
                      {{index + 1}}
                    </button>
                  </div>
                </div>
              </div>
  
              <div class="WEB-VIEW_Tit">
                <h2>협업지원시스템</h2>
                  <p>- 건축, 건설, 엔지니어링 업체들의 사내 운영 시스템 개발</p>
                  <p>- 게시판, 일정관리, 연락처 관리 등 맞춤형 개발</p>
              </div>
            </div>
            
  
            <div class="LMSWrap" id="list3 product-section-3">
              <div class="LMS_Tit">
                <h2>프로젝트 관리 시스템</h2>
                <p>
                  - 용역 수행을 하는 회사들의 프로젝트 관리를 도와주는 시스템 개발<br>
                  -전자 결재, 프로젝트 투입 자원 분석, 참여인원들 간의 의사소통(메신저) 지원
                </p>
              </div>
  
              <div class="item">
                <div class="Slider_Container">
                  <div class="Slider" :style="{ transform: `translateX(-${currentPr * 100}%)` }">
                    <!-- <img src="../../assets//images//icons/router22.png" class="prevSlide" @click="prevSlideWv"> -->
                    <div class="Slider_Item" v-for="(image, index) in Primages" :key="index">
                      <img :src="image" alt="Image {{index + 1}}">
                    </div>
                    <!-- <img src="../../assets//images//icons/router2.png" class="nextSlide" @click="nextSlideWv"> -->
                  </div>
            
                  <div class="Slider_Buttons">
                    <button v-for="(image, index) in Primages" :key="index" @click="goToSlidePr(index)" :class="{ active: index === currentPr}">
                      {{index + 1}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="PSWrap" id="list4 product-section-4">
              <div class="item">
                <div class="Slider_Container">
                  <div class="Slider" :style="{ transform: `translateX(-${currentPs * 100}%)` }">
                    <!-- <img src="../../assets//images//icons/router22.png" class="prevSlide" @click="prevSlideWv"> -->
                    <div class="Slider_Item" v-for="(image, index) in Psimages" :key="index">
                      <img :src="image" alt="Image {{index + 1}}">
                    </div>
                    <!-- <img src="../../assets//images//icons/router2.png" class="nextSlide" @click="nextSlideWv"> -->
                  </div>
            
                  <div class="Slider_Buttons">
                    <button v-for="(image, index) in Psimages" :key="index" @click="goToSlidePs(index)" :class="{ active: index === currentPs}">
                      {{index + 1}}
                    </button>
                  </div>
                </div>
              </div>
  
              <div class="PMIS_Tit">
                <h2>BIM 라이브러리관리 시스템</h2>
                <p>
                  - 라이브러리 관리방안 컨설팅<br>
                  - BIM 기반의 설계프로젝트 진행에 사용되는 라이브러리 통합 관리 시스템
                </p>
              </div>
            </div>
  
            <div class="OtherWrap" id="list5 product-section-5">
              <div class="Other_Tit">
                <h2>기타 응용프로그램 개발</h2>
                <p>
                  - 웹, 데스크탑, 모바일 기반의 솔루션 개발<br>
                  - 최신의 기술(Ajax, RSS, RIA 등)을 활용한 솔루션 구축 가능
                </p>
              </div>
              <div class="item"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="Product_Footer">
      <Footer/>
    </section>
  </div>
</template>

<style scoped>
@import "../../assets/css/reset.css";
@import "../../assets/css/product.css";
.slider {
  transition: transform 1s ease;
}
.active {
  background-color: #4d8fcc; 
  color: #4d8fcc; 
}
#FooterWrapper {
  position: relative;
}
</style>

<script>
import Header from "../../assets/components/PageHeader.vue"
import Footer from "../../assets/components/PageFooter.vue"

export default {
  name: "App",
  components: {
    Header,
    Footer
  },

  data() {
    return {
      currentSlide:0,
      currentWv:0,
      currentPr:0,
      currentPs:0,
      images: [
        require('@/assets/images/product/Modeling/modeling_01.jpg'),
        require('@/assets/images/product/Modeling/modeling_02.jpg'),
        require('@/assets/images/product/Modeling/modeling_03.jpg'),
      ],
      Wvimages: [
        require('@/assets/images/product/Collabo/collabo_01.png'),
        require('@/assets/images/product/Collabo/collabo_02.png'),
        require('@/assets/images/product/Collabo/collabo_03.png'),
      ],
      Primages: [
        require('@/assets/images/product/Management/xeno.png'),
        require('@/assets/images/product/Management/pr_01.png'),
        require('@/assets/images/product/Management/pr_02.png'),
        require('@/assets/images/product/Management/pr_03.png'),
        require('@/assets/images/product/Management/pr_04.png'),
        require('@/assets/images/product/Management/pr_05.png'),
      ],
      Psimages: [
        require('@/assets/images/product/Library/library_01.png'),
        require('@/assets/images/product/Library/library_02.png'),
        require('@/assets/images/product/Library/library_03.png'),
        require('@/assets/images/product/Library/library_04.png'),
        require('@/assets/images/product/Library/library_05.png'),
        require('@/assets/images/product/Library/library_06.png'),
        require('@/assets/images/product/Library/image5.png'),
        require('@/assets/images/product/Library/image7.png'),
        require('@/assets/images/product/Library/image10.png'),
        require('@/assets/images/product/Library/image11.png'),
      ],
    }
  },

  computed: {
    imagesCount() {
      return this.images.length;
    },
  },

methods: {
    goToSlide(index) {
        this.currentSlide = index;
    },

    goToSlideWv(index) {
        this.currentWv = index;
    },

    goToSlidePr(index) {
        this.currentPr = index;
    },

    goToSlidePs(index) {
        this.currentPs = index;
    },

    prevSlide() {
      this.currentSlide = (this.currentSlide - 1 + this.imagesCount) % this.imagesCount;
    },
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.imagesCount;
    },
    prevSlideWv() {
      this.currentWv = (this.currentWv - 1 + this.imagesCount) % this.imagesCount;
    },
    nextSlideWv() {
      this.currentWv = (this.currentWv + 1) % this.imagesCount;
    },
    prevSlidePr() {
      this.currentPr = (this.currentPr - 1 + this.imagesCount) % this.imagesCount;
    },
    nextSlidePr() {
      this.currentPr = (this.currentPr + 1) % this.imagesCount;
    },
    prevSlidePs() {
      this.currentPs = (this.currentPs - 1 + this.imagesCount) % this.imagesCount;
    },
    nextSlidePs() {
      this.currentPs = (this.currentPs + 1) % this.imagesCount;
    },


      
    scrollToSection(id) {
      const el = document.getElementById(id);
      if (el === null) {
        console.warn('Element with ID ${id} not found');
        return;
      }
      el.scrollIntoView({ behavior: 'smooth' });
    },

    handleOutsideClick(event) {
      // 모달 팝업과 모달 내부 요소가 아닌 외부를 클릭하면 모달 닫기
      const modalContainer = document.querySelector(".Modal_Container");
      if (modalContainer && !modalContainer.contains(event.target)) {
        this.closeModal();
      }
    },

  },
  created() {
  const slideInterval = 3500; // 2.5초마다 슬라이드 변경

    // 슬라이드 섹션별로 setInterval 설정
    setInterval(() => {
      this.nextSlide();
    }, slideInterval);

    setInterval(() => {
      this.nextSlideWv();
    }, slideInterval);

    setInterval(() => {
      this.nextSlidePr();
    }, slideInterval);

    setInterval(() => {
      this.nextSlidePs();
    }, slideInterval);
  },

  mounted() {
        window.scrollTo(0, 0);
    }
}
</script>
