<template>
    <div id="Dp_Root">
        <div id="Dp_Header">
            <Header />
        </div>
        
        <div id="Dp_Body">
            <div class="Dp_Container">
                <div class="DpTitle">
                    <h4>인사제도</h4>
                    <p>구성원이 창의성과 역량을 자발적으로 발휘할 수 있도록<br>성과 중심의 공정한 인사 관리와
                    수평적이고 유연한 조직문화를 추구합니다.</p>
                </div>
    
                <div class="Dp_Lists">
                <!-- 평가제도 start -->
                <div class="evDiv">
                    <div class="evTitle">
                        <h2>평가제도</h2>
                        <p>"평가결과는 보상/승진 제도와 연계되어 활용되며, 평가 종료 후 개인별 피드백을 통해 공정성을 높이고, 개인의 성장발전을 추구합니다"</p>
                    </div>
    
                    <div class="evContent"> 
                        <img src="../assets/images/people/S1.png" alt="">
                    </div>
                </div>
                <!-- 평가제도 end -->
    
    
                <!-- 직위체계 start -->
                <div class="psDiv">
                    <div class="psTitle">
                        <h2>직위체계</h2>
                        <p>"연공서열이 아닌 개인의 성과와 역량에 기반한 직위체계를 구축하고 있습니다.</p>
                    </div>
    
                    <div class="psContent">
                        <img src="../assets/images/department/S2.png" alt="">
                    </div>
                </div>
                <!-- 직위체계 end -->
    
    
    
                <!--  복리후생 start -->
                <div class="wfDiv">
                    <div class="wfTitle">
                        <h2>복리후생</h2>
                        <p>BIMPeers는 건강한 업무환경, 지속적인 육성과 성장, 가족과 함께 누리는 헤택을 중시합니다.</p>
                    </div>
    
                    <div class="wfContent">
                        <img src="../assets/images/people/S3.png" alt="">
    
                    </div>
                </div>
                <!-- 복리후생 end -->
                </div>
            </div>
        </div>

        <div id="Dp_Footer">
            <Footer />
        </div>
    </div>
</template>

<style scoped>
    @import "../assets/css/department.css";

    #Footer_Wrapper {
        grid-row: 3;
    }
</style>


<script>
    import Header from "../assets/components/PageHeader.vue"
    import Footer from "../assets/components/PageFooter.vue"
    export default {
        name:'App',
        components: {
            Header,
            Footer
        },

        data() {
        return {
                showAbout_C:false,
                showS_category:false
            };
        },
        methods: {                                                                         
            toggleAbout_C() {
                this.showAbout_C = !this.showAbout_C;
            },
            toggleAboutSub_C() {
                this.showS_category = !this.showS_category;
            },
        },
        mounted() {
            window.scrollTo(0, 0);
        }
    }

</script>

