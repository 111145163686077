import { createWebHistory, createRouter } from 'vue-router';
import MainPage from '../views/MainPage.vue';
import History from '../views/about/histoty.vue';
import Vision from '../views/about/vision.vue';
import CiInfo from '../views/about/ciInfo.vue';
import Contact from '../views/about/contact.vue';
// import ContactT from '../views/about/contact _T.vue';

import Product from '../views/business/Product.vue';
import Project from '../views/business/Project.vue';
import ProjectDetail from '../views/business/ProjectDetail.vue';


import department from '../views/Department.vue';
import talent from '../views/idealTalent.vue';
import members from '../views/people/members.vue';

import recruitProcess from '../views/recruit/RecruitProcess.vue';
import recruitList from '../views/RecruitList.vue';
import recruitListDetail from '../views/RecruitListDetail.vue';
import apply from '../views/Apply.vue';
import bimil from '../views/business/bimil.vue';
import release from "../views/release.vue"

const routes = [
    {
      path: '/',
      name: 'Main',
      component: MainPage,
    },
    {
      path: '/about/history',
      name: 'History',
      component: History,
    },
    {
      path: '/about/vision',
      name: 'Vision',
      component: Vision,
    },
    {
      path: '/about/ci',
      name: 'CiInfo',
      component: CiInfo,
    },
    {
      path: '/about/contact',
      name: 'Contact',
      component: Contact,
    },
    // {
    //   path: '/about/contact2',
    //   name: 'ContactT',
    //   component: ContactT,
    // },

    {
      path: '/product',
      name: 'Product',
      component: Product,
    },

    {
      path: '/project',
      name: 'Project',
      component: Project,
    },

    {
      path: '/project/:name',
      name: 'ProjectDetail',
      component: ProjectDetail,
    },

    {
      path: '/people/talent',
      name: 'talent',
      component: talent,
    },
    {
      path: '/people/department',
      name: 'department',
      component: department,
    },
    {
      path: '/people/members',
      name: 'members',
      component: members,
    },
    {
      path: '/recruit/process',
      name: 'recruitProcess',
      component: recruitProcess,
    },
    {
      path: '/recruit/list',
      name: 'recruitList',
      component: recruitList,
    },
    {
      path: '/recruit/list:name',
      name: 'recruitListDetail',
      component: recruitListDetail,
    },
    {
      path: '/recruit/apply',
      name: 'apply',
      component: apply,
    },
    {
      path: '/bimil',
      name: 'bimil',
      component: bimil,
      beforeEnter: (to, from, next) => {
        window.location.href = 'https://bimil.bimpeers.com/';
      }
    },
    {
      path: '/bimil/release',
      name: 'release',
      component: release,
    },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// let isDownloading = false;

// router.beforeEach((to, from, next) => {
//   if (to.path === '/release' && !isDownloading) {
//     const fileUrl = 'http://localhost:8082/files/BIMIL-1.0.0_Release.exe';
//     window.open(fileUrl, '_blank');
//     isDownloading = true; // 파일 다운로드 시작 시 플래그를 true로 설정
//     next(false); // 파일 다운로드 후 라우터 이동을 막음
//   } else {
//     next(); // 파일 다운로드 중이거나 다른 페이지로 이동할 때는 계속 진행
//   }
// })


export default router;
