<template>
    <div id="vision_Root">
        <div id="Vision_Header">
            <Header />
        </div>
        
        <div id="Vision_Body">
            <div class="Vision_Container">
                <div class="aboutTitle">
                    <div class="title">
                        <h2>We Make Better World</h2>
                        <p>
                            모두가 혁신화 된 기술을 경험할 수 있는 세상을 위해 노력합니다.
                        </p>
                    </div>
                </div>

                <div class="Vision_Contents">
                    <div class="aboutImages" id="MVC">
                        <div class="dashLineT">
                            <div class="arrow"></div>
        
                            <dl class="aboutImages_title">
                                <dt>Mission</dt>
                                <dd class="ko">IT 기술을 활용한 더 나은 건설환경 구축</dd>
                                <dd class="en">Building a Better Construction Environment<br> Using IT Technology</dd>
                            </dl>
                            
                            <div class="img_left">
                            </div>
                        </div>
        
        
                        <div class="dashLineM">
                            <dl class="aboutImages_title">
                                <dt>Vision</dt>
                                <dd class="ko">건설 디지털 데이터 처리를 위한<br>BIM 기반 통합 플랫폼 구축</dd>
                                <dd class="en">Building BIM-based Integrated Platform for<br>Digital Data Processing in Construction</dd>
                            </dl>
        
                            <div class="img_right"></div>
                        </div>
        
                        <div class="dashLineB">
                            <dl class="aboutImages_title">
                                <dt>Core-Value</dt>
                                <dd class="ko">기술선도 | 변화 선도 | 품질경영 | 인간존중</dd>
                                <dd class="en">
                                    Technical Leadership | Leadership of Change<br>
                                    Quality management | Respect for human beings
                                </dd>
                            </dl>
                            <div class="Limg2"></div>
                        </div>  
                    </div>
                </div>
            </div>
        </div>

        <div id="Vision_Footer">
            <Footer />
        </div>
    </div>


</template>

<style scoped>
@import "../../assets/css/about/vision.css";
@import "../../assets/css/reset.css";

.Footer_Wrapper {
    position:relative;
}
</style>




<script>
import Header from "../../assets/components/PageHeader.vue"
import Footer from "../../assets/components/PageFooter.vue"
export default { 
    name:'App',
    components: {
        Header,
        Footer
    },

    methods: {
        scrollTo(id) {
            document.getElementById(id).scrollIntoView({behavior:'smooth'})
        }
    },

    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>