<template>
    <div id="Project_Root">
        <section id="Project_Header">
            <Header />
        </section>
        <section id="ProJect_Body">
            <div class="Project_Container">
                <div class="Project_Tit">
                    <h2>수행 프로젝트</h2>
                    <p>
                        BIMPeer는<strong> 기술선도와 품질경영으로</strong> 15년 동안 고객사와 함께 성장해 왔습니다.
                    </p>
                </div>
                <div id="Project_Contents">
                    <p class="prStatus">
                        <strong>{{ filteredProjectTables.length }}</strong>
                        개의 프로젝트가 등록되어 있습니다.
                    </p>
                    <div class="Proj_ComboBox">
                        <div class="FirstSelect_Container">
                            <select v-model="selectedOption" class="FirstSelect_Div">
                                <!-- <option disabled value="">====== 선택하세요 ======</option> -->
                                <option value="all">전체</option>
                                <option value="year">연도별</option>
                                <option value="keyword">키워드별</option>
                                <option value="client">고객사별</option>
                            </select>
                        </div>
                        <div class="SecondSelect_Container" v-show="SecondSelectShow()">
                            <input type="text" readonly class="SecondSelect_Div" :value=secondSelectDivValue>
                            <div class="filterbox" ref="filterbox">
                                <div class="filterItem" v-for="item in filteredOptions" :key="item">
                                    <input type="checkbox" :value="item" @change="addSecondSelectDivValue(item,$event)">
                                    <span>{{item}}</span>
                                </div>
                            </div>
                            <svg height="40" width="10">
                                <path d="M0,25 4,29 8,25" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>
                    <div id="Project_ItemBox">
                        <div class="Project_item" v-for="item in paginatedOptions" :key="item.code">
                            <div class="Item_Header">
                                <router-link class="fir" :to="'/project/'+encodeURIComponent(item.name)">
                                {{ item.name }}
                                <div class="tootip">{{ item.name }}</div>
                                </router-link>
                                <router-link class="las" :to="'/project/'+encodeURIComponent(item.name)">-></router-link>
                            </div>
                            <p class="duration">{{ item.duration }}</p>
                            <p class="client">{{ item.client }}</p>
                            <p class="tags">[Keyword] {{ item.keyword }}</p>
                        </div>


                    </div>
                    <div class="pagination">
                        <ul>
                            <li>
                                <button @click="goToFirstPage" :disabled="currentPage === 1">
                                <img src="../../assets/images/icons/paging_f.png" alt="">
                                </button>
                            </li>
                    
                            <li>
                                <button @click="currentPage = Math.max(1, currentPage - 1)">
                                <img src="../../assets/images/icons/paging.png" alt="">
                                </button>
                            </li>
                    
                            <li v-for="pageNumber in displayedPageNumbers" :key="pageNumber" :class="{ active: pageNumber === currentPage }">
                                <button @click="currentPage = pageNumber">{{ pageNumber }}</button>
                            </li>
                    
                            <li>
                                <button @click="goToNextPage">
                                <img src="../../assets/images/icons/paging.png" alt="" class="next">
                                </button>
                            </li>
                    
                            <li>
                                <button @click="goToLastPage" :disabled="currentPage === filteredTotalPages">
                                    <img src="../../assets/images/icons/paging_f.png" alt="" class="Last">
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section id="Project_Footer">
            <Footer />
        </section>
    </div>
</template>

<style scoped>
.project_Tit {
    position: relative;
}
.tooltip {
    position: absolute;
    top: 30%; /* 위쪽에 표시하려면 top 값을 조절할 수 있습니다. */
    left: 15px;
    display: none;
    font-size: 14px;
    color:#787878;
    font-weight: normal;
    background: #ccc;
    /* 나머지 스타일 설정 */
}
.tooltip.show {
    display: block; /* Show the tooltip when the 'show' class is applied */
}

@import "../../assets/css/reset.css";
@import "../../assets/css/project.css";

</style>



<script>
import Header from "../../assets/components/PageHeader.vue"
import Footer from "../../assets/components/PageFooter.vue"
import ProjectTable from "../../assets/tables/ProjList.json";
import { createWebHistory } from 'vue-router';


export default { 
    
    name:'App',
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            totalPages : 0,
            tooltipVisible: false,
            tooltipText: '',
            client:"",
            jsonData: ProjectTable,
            pageSize: 9,
            currentPage: 1,
            selectedPr:'',
            selectedCl:'',
            selectedKey:'',
            selectedOption:'all',
            selectedFilter:'',
            filteredOptionsData:[],
            filters: {
                name:null,
                client:null,
                duration:null,
                keyword:null,
                year:null,
                code:null,
                //isPublic:null
            },
            mouseX: 0,
            mouseY: 0,
            isInit : false,
            secondSelectDivValue : "",
            secondSelectList :[],
        };
    },
    mounted () {
        window.scrollTo(0, 0);
        this.name = this.$route.params.name;
        this.jsonData = ProjectTable;
        this.totalPages = this.jsonData.length/6;
        this.populateSecondComboBox();
        const loadCurPage = this.getCookie("currentPage");
        const loadSelectedOption = this.getCookie("selectedOption");
        const loadSecondSelectList = this.getCookie("secondSelectList");
        
        if(loadSelectedOption)
        {
            this.selectedOption = loadSelectedOption;
        }
        if(loadSecondSelectList)
        {
            this.secondSelectList = loadSecondSelectList.split(',');
        }
        if(loadCurPage)
        {
            this.currentPage = Number(loadCurPage);
        }
        this.$nextTick( ()=>{
            this.isInit = true;
            document.getElementById('proLink').addEventListener('click',()=>{
                this.deleteCookie("currentPage");
                this.deleteCookie("selectedOption");
                this.deleteCookie("selectedFilter");
                this.selectedOption = 'all';
                this.currentPage = 1;
            })
        });
    },
    watch: {
        selectedOption: function(newOption, oldOption) {
            // Call the method to populate the second combo box when the selected option changes
            if (newOption !== oldOption) {
                this.populateSecondComboBox();
            }
            this.setCookie("selectedOption",this.selectedOption,1);
            //this.secondSelectDivValue = " " + this.filteredOptions[0];
            this.$nextTick(()=>{
                const t = {checked : true};
                let e = {target : t}
                if(this.isInit)
                {
                    this.secondSelectList = [];
                    this.$refs.filterbox.firstElementChild.firstElementChild.checked = true;
                    this.addSecondSelectDivValue(this.filteredOptions[0],e);
                }
                else
                {
                    for(const item of this.$refs.filterbox.children)
                    {
                        const checkBox = item.firstElementChild;

                        for(const name of this.secondSelectList)
                        {
                            if(checkBox.value.includes(name))
                            {
                                checkBox.checked = true;
                            }
                        }
                    }
                    this.setSecondSelectDivValue();
                }
            })
        },
        currentPage()
        {
            this.setCookie("currentPage",this.currentPage,1);
        },
    },


    computed: {
    isViewAllSelected() {
        return this.selectedOption === 'all';
    },

    paginatedOptions() {
        if (this.isViewAllSelected) {
            return this.jsonData.slice(
            (this.currentPage - 1) * this.pageSize,
            this.currentPage * this.pageSize
            );
        } else {
            if (this.selectedOption === 'year') {
            return this.jsonData
                .filter((item) => this.secondSelectList.filter(x=> item.year.includes(x)).length > 0)
                .slice(
                (this.currentPage - 1) * this.pageSize,
                this.currentPage * this.pageSize
                );
            } else if (this.selectedOption === 'keyword') {
            return this.jsonData
                .filter((item) => this.secondSelectList.filter(x=> item.keyword.includes(x)).length > 0)
                .slice(
                (this.currentPage - 1) * this.pageSize,
                this.currentPage * this.pageSize
                );
            } else if (this.selectedOption === 'client') {
            return this.jsonData
                .filter((item) => this.secondSelectList.filter(x=> item.client.includes(x)).length > 0)
                .slice(
                (this.currentPage - 1) * this.pageSize,
                this.currentPage * this.pageSize
                );
            } else {
            return [];
            }
        }
    },

    paginatedProjects() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    return this.filteredProjectTables.slice(startIndex, endIndex);
    },

    filteredProjectTables() {
        if (this.selectedOption === 'all') {
            return this.jsonData;
        } else if (this.selectedOption === 'year') {
            return this.jsonData.filter((item) => this.secondSelectList.filter(x=> item.year.includes(x)).length > 0);
        } else if (this.selectedOption === 'keyword') { console.log(this.jsonData,this.secondSelectList)
            return this.jsonData.filter((item) => this.secondSelectList.filter(x=> item.keyword.includes(x)).length > 0);
        } else if (this.selectedOption === 'client') {
            return this.jsonData.filter((item) => this.secondSelectList.filter(x=> item.client.includes(x)).length > 0);
        } else {
            return [];
        }
    },

    filteredOptions() {
        if (this.selectedOption === 'all') {
            return [];
        } else if (this.selectedOption === 'year') {
            const years = this.jsonData.map((item) => item.year);
            return [...new Set(years)];
        } else if (this.selectedOption === 'keyword') {
            const keywords = this.jsonData.flatMap((item) => item.keyword.split(" | "));
            const keywordSet = [...new Set(keywords)];
            let returnList = [];
            for(let keyword of keywordSet)
            {
                const splitKey = keyword.split('/');
                splitKey.forEach(key => {
                    returnList.push(key.toString().trim());
                });
            }
            returnList = [ "모델&설계 자동화","도면화","품질관리","협업지원","프로젝트 관리","LMS","시스템연계","R&D","Estimate","기타"];//[...new Set(returnList)];
            return returnList;
        } else if (this.selectedOption === 'client') {
            const clients = [...new Set(this.jsonData.map((item) => item.client))];
            let returnList = [];
            for(let keyword of clients)
            {
                returnList.push(keyword.toString().trim());
            }
            returnList = [...new Set(returnList)];
            returnList.sort();
            return returnList;
        } else {
            return [];
        }
    },

    filteredTotalPages() {
        return Math.ceil(this.filteredProjectTables.length / this.pageSize);
    },

    displayedPageNumbers() {
        const totalPages = this.filteredTotalPages;
        const current = this.currentPage;
        const pageRange = 7;

        let start = Math.max(1, current - Math.floor(pageRange / 2));
        let end = Math.min(start + pageRange - 1, totalPages);

        if (end - start + 1 < pageRange) {
        start = Math.max(1, end - pageRange + 1);
        }

        return Array(end - start + 1)
        .fill()
        .map((_, index) => start + index);
    },
},


methods: {
    setCookie(name, value, exp)
    {      
        const date = new Date();
        date.setTime(date.getTime() + exp*24*60*60*1000);
        document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';  
    },
    getCookie(name)
    {
        const value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return value? value[2] : null;
    },
    deleteCookie(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    SecondSelectShow()
    {
        if(this.selectedOption == 'all')
        {
            return false;
        }
        return true;
    },
    showTooltip(item, event) {
        this.tooltipText = item.name;
        this.tooltipVisible = true;

        const gridElement = event.target;
        const gridRect = gridElement.getBoundingClientRect();

        this.tooltipX = event.clientX - gridRect.left;
        this.tooltipY = event.clientY - gridRect.top;
    },
    hideTooltip() {
        this.tooltipVisible = false;
    },
    //localStorage에 저장..
    storeFilters() {
        localStorage.setItem('filterSelections', JSON.stringify(this.filters));
    },
    setSecondSelectDivValue()
    {
        this.secondSelectDivValue = " ";
        for(let i in this.secondSelectList)
        {
            this.secondSelectDivValue += this.secondSelectList[i];
            if(Number(i)+1 < this.secondSelectList.length)
            {
                this.secondSelectDivValue += " / ";
            }
        }

        if(this.isInit)
        {
            this.currentPage = 1;
        }
        this.setCookie("secondSelectList",this.secondSelectList,1);
    },
    addSecondSelectDivValue(value,e)
    {
        if(e.target.checked)
        {
            this.secondSelectList.push(value);
        }
        else
        {
            this.secondSelectList = this.secondSelectList.filter(x=>x!=value);
        }
        this.setSecondSelectDivValue();
    },
    getConfigUrl(item) {
        console.log('Item Name:', item.name);

        const projectName = encodeURIComponent(item.name); // Encode the project name to handle special characters in the URL
        const url = `/project/${projectName}`;
        console.log('Generated URL:', url);

        return url;
    },
    populateSecondComboBox() {

            if (this.selectedOption === 'year') {
            // Filter the options for the second combo box based on the selected year
            this.filteredOptionsData = this.jsonData
                .filter((item) => item.year === this.selectedFilter)
                .map((item) => item.client);
            } else if (this.selectedOption === 'keyword') {
            // Filter the options for the second combo box based on the selected keyword
            // Assuming your keywords are separated by " | " as you have in the filteredOptions() method
            this.filteredOptionsData = this.jsonData
                .filter((item) => item.keyword.includes(this.selectedFilter))
                .map((item) => item.client);
            } else if (this.selectedOption === 'client') {
            // Filter the options for the second combo box based on the selected client
            this.filteredOptionsData = this.jsonData
                .filter((item) => item.client === this.selectedFilter)
                .map((item) => item.year);
            } else {
            // If 'all' is selected or no option is selected, show all options for the second combo box
            this.filteredOptionsData = [];
            }
        },

        
    
    goToFirstPage() {
    this.currentPage = 1;
    },
    goToLastPage() {
    this.currentPage = this.filteredTotalPages;
    },
    goToNextPage()
    {
        console.log(this.currentPage)
        this.currentPage = Math.min(this.totalPages, this.currentPage + 1);
    },
    clickYear () {
        this.showYear = !this.showYear;
        this.showCl = false;
        this.showKey = false;
    },  
    clickClient () {
        this.showCl = !this.showCl;
        this.showYear = false;
        this.showKey = false;
    },
    clickKeywords () {
        this.showKey = !this.showKey;
        this.showYear = false;
        this.showCl = false;
    },
    sortTable(columnName) {
        if (this.sortColumn === columnName) {
            // toggle sort direction if same column is clicked
            this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
        } else {
            // sort by new column
            this.sortColumn = columnName;
            this.sortDirection = 'asc';
        }
        
        this.jsonData.sort((a, b) => {
            const valueA = a[columnName].toUpperCase(); // convert to uppercase
            const valueB = b[columnName].toUpperCase(); // convert to uppercase
            if (valueA > valueB) {
            return this.sortDirection === 'asc' ? 1 : -1;
            } else if (valueA < valueB) {
            return this.sortDirection === 'asc' ? -1 : 1;
            } else {
            return 0;
            }
        });
    }
}
}
</script>

