<template>
    <div id="talent_Root">
        <div id="talent_Header">
            <Header />
        </div>

        <div id="people_Body">
            <div class="people_Container">
                <div class="peopleTitle_image">
                    <h2>인재상</h2>
                    <h3>성장을 위한 가장 확실한 방법은 뛰어난 동료들 사이에 있는 것 입니다.</h3>
                </div>
    
                <div class="peopleText">
                    <img :src="getImageSource">
                </div>
            </div>
        </div>

        <div id="talent_Footer">
            <Footer/>
        </div>
    </div>

</template>

<style scoped>
    @import "../assets/css/talent.css";
    @import "../assets/css/reset.css";

    #Footer_Wrapper {
        position: absolute;
    }
</style>

<script>
import Header from "../assets/components/PageHeader.vue"
import Footer from "../assets/components/PageFooter.vue"
export default {
    name:'App',
    components: {
        Header,
        Footer
    },
    data() {
    return {
            showAbout_C:false,
            showS_category:false
        };
    },
    methods: {                                                                         
        toggleAbout_C() {
            this.showAbout_C = !this.showAbout_C;
        },
        toggleAboutSub_C() {
            this.showS_category = !this.showS_category;
        },
    },
    computed: {
        getImageSource() {
        // Check the screen width and return the appropriate image path
            return window.innerWidth >= 500 ? require("../assets/images/people/talentWide.png") : require("../assets/images/people/ttt.png");
        }
    },

    mounted() {
        window.scrollTo(0, 0);
    }

}
</script>
