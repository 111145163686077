<template>
    <div id="contact_Root">
        <div id="contact_Header">
            <Header />
        </div>
        
        <div id="Contact_Body">
            <div class="contact_Container">
                <div class="contact_tit">
                    <h4>오시는 길</h4>
                    <p><strong>언제나 여러분과 함께하는</strong> BIMPeers</p>
                </div>

                <div class="contact_Contents">
                    <div class="address">
                        <div class="address_table">
                            <!-- <img src="../../assets//images/logo/logo-w.png" alt=""> -->
                            <h4>BIMPeers</h4>
                            <ul class="addressWrap">
                                <!-- <li class="txt">주소</li> -->
                                <li>서울특별시 서초구 서운로 26-1, 보일빌딩 501 & 601호</li>
                                <li>501 & 601, Seoun-ro, Seocho-gu, Seoul</li>
                                
                            </ul>
                        </div>
    
                        <div class="Contact_table">
                            <h4>
                                <img src="../../assets//images/icons/tel.png" alt="">제품 및 커스터마이징 문의</h4>
                            <ul class="addressWrap">
                                <li >이메일&nbsp;&nbsp;help@bimpeers.com</li>
                                <li>전&nbsp;&nbsp;&nbsp;&nbsp;화&nbsp;&nbsp;070-7767-2505</li>
                            </ul>
                        </div>
    
                    </div>
    
    
                    <div class="mapDiv">
                        <img src="../../assets//images/about/map.jpg" alt="">
                    </div>
        
    
                    <div class="contact_Info">
                        <div class="Transportation_Container">
                            <h4>
                                <img src="../../assets//images/icons/bus.png" alt="">
                                대중교통 이용시
                            </h4>
    
                            <ul>
                                <li class="busSub">지하철 이용시</li>  
                                <li>양재역 (신분당선 / 3호선) 하차 (도보 약 5분)</li>
                                <li class="busSub">버스 이용시</li>  
                                <li>광역버스 | <strong>[뱅뱅사거리 정류장]</strong> 하차 (도보 약 5분)</li>
                                <li>마을버스 | <strong>[서초문화정보예술학교 후문 정류장]</strong> 하차 (도보 약 1분)</li>
                            </ul>
                        </div>
    
                        <div class="Car_Container">
                            <h4>
                                <img src="../../assets//images/icons/car.png" alt="">
                                자차 이용시
                            </h4>
    
                            <ul>
                                <li class="busSub">고속도로 이용시 (지방에서 오시는 경우)</li>  
                                <li>용인서울고속도로 - 경부고속도로 - 서초ic - 서초구청 사거리에서 좌회전 - 보일빌딩 진입</li>
                                <li class="busSub">고속도로 이용시 (서울에서 오시는 경우)</li>  
                                <li>올림픽대로 - 경부고속도로 - 서초ic - 서초구청 사거리에서 좌회전 - 보일빌딩 진입</li>
                                <li class="check">※ 주차는 방문하시기 전에 미리 말씀을 주시면 됩니다.</li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        
        <div id="Contact_Footer"> 
            <Footer />
        </div>
    </div>
</template>

<style scoped>
    @import "../../assets/css/about/contact.css";

    .Footer_Wrapper {
        position:relative;
        bottom: 0;
        left: 0 ;
        width: 100%;
        height: 120px;
    }

</style>

<script>
import Header from "../../assets/components/PageHeader.vue"
import Footer from "../../assets/components/PageFooter.vue"
export default { 
    name:'App',
    components: {
        Header,
        Footer
    },
    data() {
    return {
            showAbout_C:false,
            showS_category:false
        };
    },



    methods: {
        scrollTo(id) {
            document.getElementById(id).scrollIntoView({behavior:'smooth'})
        },
        toggleAbout_C() {
            this.showAbout_C = !this.showAbout_C;
        },
        toggleAboutSub_C() {
            this.showS_category = !this.showS_category;
        },
    },

    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>
