<template>
    <div id="process_Root">
        <div id="process_Header">
            <Header />
        </div>
        
        <div id="process_Body">
            <div class="content">
                <div class="process_Tit">
                    <h4>채용 프로세스</h4>
                    <p>BIMPeers와 함께 꿈을 이루어나갈 분들을 찾습니다.</p>
                </div>
    
                <div class="P_List_table_icon" >
                    <div class="process_txt">
                        <h2>신입 / 경력 채용 프로세스</h2>
                        
                    </div>
                    <!-- <h2>채용절차</h2> -->
                    <div class="P_ListSection">
                        <img src="../../assets/images/recruit/process.png" alt="">
                    </div>
    
                    <div class="process">
                        <dt>Step 01 | 지원서 작성</dt>
                        <dl>지원서 접수시 채용공고에 있는 모집분야 및 세부사항을 잘 확인하여
                            지원서를 온라인으로 작성 또는 링크된 채용사이트를 통해 최종 제출합니다.
                        </dl>
    
                        <dt>Step 02 | 서류전형</dt>
                        <dl>지원서의 각 항목에 기재된 내용과 자기소개서를 바탕으로,     인재상과의 부합 정도와 지원자가 지원직무 및 회사에 적합한 인재인지를 종합적으로 평가합니다.
                        </dl>
    
                        <dt>Step 03 | 역량/인성면접</dt>
                        <dl>서류전형 합격자를 대상으로 이루어지는 면접으로,
                            실제 업무를 수행하기 위한 기본/실무역량과 태도, 인성, 가치관 등을 평가하는 면접이 진행됩니다.
                            현업 실무자 및 관리자가 면접관으로 참석합니다.
                        </dl>
    
                        <dt>Step 04 | 최종합격</dt>
                        <dl>종합적으로 지원자를 평가하여 회사에 가장 적합하다고 생각한 지원자를 결정합니다.
                        </dl>
                    </div>
                </div>
    
                <div class="R_applyBtns">
                    <p><router-link to='/recruit/list'>채용정보 보기</router-link></p>
                
                </div>
            </div>
        </div>
        
        <div id="process_Footer">
            <Footer />
        </div>
    </div>
</template>

<style scoped>
@import '../../assets/css/process.css';

</style>


<script>
import Header from "../../assets/components/PageHeader.vue"
import Footer from "../../assets/components/PageFooter.vue"
export default { 
    name:'App',
    components: {
        Header,
        Footer
    },
    data() {
    return {
            showAbout_C:false,
            showS_category:false
        };
    },

    methods: {                                                                         
        toggleAbout_C() {
            this.showAbout_C = !this.showAbout_C;
        },
        toggleAboutSub_C() {
            this.showS_category = !this.showS_category;
        },
    },
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>