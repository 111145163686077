<template>
    <div class="Footer_Wrapper">
        <div class="Footer_Container">
            <p class="Footer_Tit">BIMPeers</p>

            <div class="Footer_List">
                <ul class="Address">
                    <li>서울특별시 서초구 서운로 26-1, 501 / 601호</li>
                    <li>501/601, Seoun-ro, Seocho-gu, Seoul</li>
                </ul>

                <ul class="Major">
                    <li>건축 / 건설분야 소프트웨어 개발 및 서비스</li>
                    <li>Software development and services in architecture and construction</li>
                </ul>

                <ul class="Tel">
                    <li>070-7767-2505</li>
                    <li>help@bimpeers.com</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style scoped>
@charset "UTF-8";
body {
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;
    box-sizing: border-box;
    font-family: "Pretendard, sans-serif";
}
* {
    list-style: none;
    box-sizing: border-box;
    text-decoration: none;
    margin: 0;
    padding: 0;
}
.Footer_Wrapper {
    width: 100%;
    height: 120px;
    border-top:3px solid rgba(255,255,255,.25);
    background:#042a44;
    grid-column-start:1;
    grid-column-end:12;
    display: grid;
    grid-template-columns: 1fr 1610px 1fr;
}
.Footer_Container {
    grid-column-start: 2;
    grid-column-end: 11;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
}
.Footer_List {
    display: flex;
    flex-direction: row;
}
.Footer_Tit {
    font-size:1.2rem;
    font-weight:500;
    font-family: "Pretendard, sans-serif";
    margin-bottom:18px;
    color: #fff;
}
.Footer_Container ul li {
    font-family: "Pretendard, sans-serif";
    font-size:.8rem;
    line-height:18px;
    color:#fff;
    font-weight:lighter
}
.Address {
    padding-right:50px;
    border-right:2px solid #fff;
}
.Major {
    padding:0 50px;
    border-right:2px solid #fff
}
.Tel {
    padding-left:50px;
}
</style>