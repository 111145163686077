<template>
    <div id="ProjectD_Root">
        <div id="ProjectD_Header">
            <Header />
        </div>
        
        <div id="ProD_Body">
            <div v-if="projectData" class="ProjectD_Div">
                <div class="ProD_Tit">
                    <h2>프로젝트 소개</h2>
                    <p>
                        BIMPeers는 <strong>기술선도와 품질경영으로 </strong>
                        15년 동안 고객사와 함께 성장해 왔습니다.
                    </p>
                </div>
    
                <div class="ProjectDetail_Div">
                    <dl class="ProjectDetail_title">
                        <dt class="client">{{ projectData.name }}</dt>
                        <dd class="txt" v-if="projectData.subTit">{{ projectData.subTit}}</dd>
                        <dd class="list" v-if="projectData.desc1">{{ projectData.desc1 }}</dd>
                        <dd class="list">{{projectData.desc2 }}</dd>
                        <dd class="list">{{ projectData.desc3}}</dd>
                    </dl>
    
                    <div class="ProjectDetail_information">
                        <div class="prInfo">
                            <ul class="topLeft">
                                <li class="infoTit">
                                    CLIENT&nbsp;&nbsp;|&nbsp;&nbsp;
                                    <strong>{{  projectData.client }}</strong>
                                </li>
                            </ul>
    
                            <ul class="topRight">
                                <li class="infoTit">
                                    STATUS&nbsp;&nbsp;|&nbsp;&nbsp;
                                    <strong>{{  projectData.status}}</strong>
                                </li>
                            </ul>
    
                            <ul class="bottomLeft">
                                <li class="infoTit">
                                    DURATION&nbsp;&nbsp;|&nbsp;&nbsp;
                                    <strong>{{  projectData.duration}}</strong>
                                </li>
                            </ul>
    
                            <ul class="Keyword_Div">
                                <li class="Keyword_list"></li>
                                <li class="infoTit">
                                    KEYWORDS&nbsp;&nbsp;|&nbsp;&nbsp;
                                    <strong>{{ projectData.keyword}}</strong>
                                </li>
                        </ul> 
    
                    </div>
                        
                        <div class="prThumb"></div>
                        <div class="project_thumbnails">
                            <img v-if="projectData.projectImage" :src=img @error="imageError" />
                            <p style="color:red; font-size: 16px; padding-top:25px">{{ projectData.ps }}</p>
                        </div>
                    </div>
                </div>
            </div>  
        </div>

        <div id="ProjectD_Footer">
            <Footer />
        </div>
    </div>
</template>

<style scoped>
    @import "../../assets/css/projectDetail.css";
    .Footer_Wrapper {
        position: relative;
    }
</style>



<script>
import Header from "../../assets/components/PageHeader.vue";
import Footer from "../../assets/components/PageFooter.vue";
const jsonData = require("../../assets/tables/ProjList.json");

export default {
    // 인터넷 창에 있는 뒤로가기 버튼 클릭시 직전에 본 페이지로 이동..
    beforeRouteEnter(to, from, next) {
        // Retrieve stored filter selections from localStorage
        const storedFilters = localStorage.getItem('filterSelections');
        const filters = storedFilters ? JSON.parse(storedFilters) : {};

        next(vm => {
        vm.filters = filters; // Apply the retrieved filter selections
        });
    },
    name: 'ProjectDetail',
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            projectData: null,
            img : require("../../assets/images/projectDetail/noImage.png"),
        };
    },
    created() {
        const projectName = this.$route.params.name;
        this.loadProjectData(projectName);
    },
    mounted()
    {
        window.scrollTo(0, 0);
        if(this.projectData?.projectImage)
        {
            if(this.projectData.projectImage.toString().length>0)
            {
                try
                {
                    this.img = require(`../../assets/images/projectDetail/${this.projectData.projectImage}`);
                }
                catch
                {
                    this.img = require("../../assets/images/projectDetail/noImage.png");
                }
            }
        }
    },
    watch: {
        '$route.params.name': 'loadProjectData',
    },
    methods: {
        loadProjectData(projectName) {
            const decodedProjectName = decodeURIComponent(projectName);
            const matchingProject = jsonData.find((item) => item.name.toString().includes(decodedProjectName));
            if (matchingProject) {
                this.projectData = matchingProject;
            } else {
                console.log('No matching project found.');
            }
        },
        imageError(event) {
            console.error('Error loading image:', event.target.src);
            event.target.style.display = 'none'; // Hide the image on error
        }
    },
};
</script>


